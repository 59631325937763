<style type="text/css" scoped>
    @import "~@/assets/css/common.css";
    @import "~@/assets/css/admin.css";
    @import "~@/assets/css/index.css";
    @import "~@/assets/css/list.css";
    @import "~@/assets/css/ticket-print.css";

    /deep/ .outerCotent p,
    /deep/ #pdf p {
        margin: 0 !important;
        background-color: transparent !important;
    }

    /deep/ #nobgColor p {
        background-color: transparent !important;
    }

    .cjd_title {
        margin-top: 20px;
    }

    .form-control {
        padding: unset;
    }

    .el-form-item {
        margin-bottom: unset;
        flex: 1;
    }

    .check-img {
        height: 30px;
    }

    .form-control {
        padding: 5px 10px;
        border: unset;
        /* background: #fffbf2; */
        height: unset;
        display: flex;
        align-items: center;
    }
</style>
<style scoped>
    html, body {
        font-size: 16px;
    }

    /deep/ .nobgColor p {
        background-color: transparent !important;
    }

    /deep/ .outerCotent p,
    /deep/ #pdf p {
        margin-bottom: 0;
    }

    /deep/ #pdf img:not(.userImg) {
        width: 100% !important;
    }

    /deep/ #nobgColor p {
        background-color: transparent !important;
    }

    /deep/ p {
        margin: unset;
    }

    .outerCotent,
    /deep/ #pdf span {
        font-family: 微软雅黑, 华文细黑, 宋体, 黑体, arial, Hiragino Sans GB, Microsoft Yahei, Tahoma, Arial, Helvetica, STHeiti;
        /* color: #000000; */
    }

    #pdf .pdf >>> span {
        font-family: 微软雅黑, 华文细黑, 宋体, 黑体, arial, Hiragino Sans GB, Microsoft Yahei, Tahoma, Arial, Helvetica, STHeiti;
        /* color: #000000; */
    }
</style>
<style type="text/css" scoped>
    /deep/ .el-input__inner {
        /* background-color: unset; */
        width: 50%;
        height: 35px;
        /* border: unset; */
    }

    /deep/ .el-textarea__inner {
        background-color: #fff9eb;
    }

    /deep/ .el-form-item__content {
        line-height: inherit;
    }

    .el-form-item--small .el-form-item__error {
        margin-top: 2px;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 100%;
    }

    /deep/ .el-form-item__error {
        position: absolute !important;
        top: 20%;
        left: 100%;
        text-align: center;
        width: 120px;
        height: 21px;
        border-radius: 2px;
        font-size: 12px;
        line-height: 21px;
        z-index: 99;
    }

    label {
        margin: 0;
        background-color: #f3f5fb;
        color: #000;
        font-weight: 500;
    }

    .input-group {
        border-top: 1px solid #babbbe;
        border-left: 1px solid #babbbe;
        border-right: 1px solid #babbbe;
    }

    .input-group:last-child {
        border-bottom: 1px solid #babbbe;
    }

    .center-list-title .iconfont {
        color: #2886bd;
    }

    .news-main {
        min-height: 550px;
    }

    .div_enrollList_tag {
        width: auto !important;
        padding-left: 5px;
        padding-right: 5px;
    }

    .checkImg2 {
        right: 51%;
        height: 30px;
        top: 8px;
    }

    .msgLabel {
        height: 30px;
        min-height: auto;
        color: #000000;
    }
</style>
<script>
    import {getcode} from "@/api/index.js";
    import {getZkzInfo, zkzSearch} from "@/api/grade/grade";
    import {getMbInfo} from "@/api/examination";
    import {format, getBase64Image, getBase64} from "@/libs/common";
    import $ from "jquery";
    import {
        getPrintTemplate,
        getZkzSubject,
        downLoad,
        adminGetZkz,
        getTicketPDF,
        getTicketPDFNoLogin,
        getCodeZkz,
        getCodeZkzKw
    } from "@/api/grade/grade.js";
    import {urlToBase} from "@/api/index";
    import {noChange} from "@/assets/js/nochange.js"

    /**
     * Dashboard component
     */
    export default {
        components: {},
        data() {
            return {
                testSid: "", //考试Sid
                formData: {
                    ksxm: "",
                    zjhm: "",
                    zkzh: "",
                    zkzyxz: false,
                    code: "",
                    uuid: "",
                    zkzkid: "",
                },
                imgCode: {
                    uuid: "",
                    img: "",
                    code: "",
                },
                zkzInfo: {},
                cxtj: [],
                zkzgzcnhList: [], //准考证承诺书号数组
                zkzgzcnhIndex: 0,

                timer: null,
                Countdown: 0,
                zkzsmInfo: {},
                sumWidth: 0,
                query: {},
                list2: [],
                list3: [],
                list4: [],
                sid: "",
                subVals: {},
                form: {},
                isShowZp: true,
                zpdz: "",
                mbh: "",
                mbmc2: "",
                imgUrl: "",
                ksbmbh: "",
                sfzjh: "",
                moduleType: 0,
                defaultZpdz: require("@/assets/images/person/person-none.png"),
                listChoose: [],
                examineeData: {},
                examForm: {
                    zkzmc: "",
                    zkzzysx: "",
                    zkzbzxx: "",
                },
                ruleList: [], //选择的规则模板
                zkzcnsInfoList: [], //准考证承诺书
                printShow: true,

                pageNum: 0,
                pageHeight: 1103,
                pdfHeight: 0,
                pdfNum: 1,
                islogin: "",
                userInfo: {},
                ticketData: {},
                DPI: null,
                page: 0,
                totalPage: 1,
                qrcode: ""
            };
        },
        watch: {},
        methods: {
            // 渲染创建好的模板
            getTemplateList(sMid) {
                this.list2 = [];
                this.list3 = [];
                this.list4 = [];
                let func;
                let param;
                if (sMid) {
                    func = getPrintTemplate;
                    param = sMid;
                    func(this.query.mbh).then((res1) => {
                        if (res1.status) {
                            this.ticketData = res1.data;
                            if (!res1.data.sfxszp) {
                                this.isShowZp = false;
                                this.$nextTick(() => {
                                    this.hiddenZp();
                                });
                            } else {
                                let fileId =
                                    "zkz_" + this.query.sid + "_" + this.examineeData.zjhm;
                              if (this.zkzInfo.sfkwks) {
                                fileId =
                                    "kwzkz_" + this.query.sid + "_" + this.examineeData.sfzjh;
                              }
                                if (this.zkzInfo.ksbmbh) {
                                    fileId =
                                        "bm_" + this.zkzInfo.ksbmbh + "_" + this.examineeData.zjhm;
                                }
                                if (this.islogin) {
                                    this.getFileUrl(fileId, true).then((res) => {
                                        if (res.status) {
                                            this.zpdz = res.data;
                                            $(".userImg").each((x) => {
                                                $(".userImg")[x].src = this.zpdz;
                                            });
                                        }
                                    });
                                } else {
                                    downLoad(fileId, this.zpUuid).then((res) => {
                                        if (res.status) {
                                            this.zpdz = res.data;
                                            $(".userImg").each((x) => {
                                                $(".userImg")[x].src = this.zpdz;
                                            });
                                        }
                                    });
                                }
                                this.isShowZp = true;
                                this.hiddenZp();
                            }
                            if (res1.data.sfhxbj) {
                                this.moduleType = 1;
                                this.pageHeight = Math.floor((1103 / 297) * 210);
                                $("#pdf").css("width", "1103");
                            } else {
                                this.moduleType = 0;
                            }
                            res1.data.printTemplateFieldBeanList.forEach((item) => {
                                if (this.examForm.zpysyq != 4) {
                                    if (item.zdfl == 1) {
                                        this.list2.push(item);
                                    } else if (item.zdfl == 2) {
                                        this.list3.push(item);
                                    } else if (item.zdfl == 3) {
                                        this.list4.push(item);
                                    }
                                } else {
                                    this.list3.push(item);
                                }
                            });
                            this.getTableRate();
                            let imgid = res1.data.cjbjwj;
                            if (imgid) {
                                if (this.islogin) {
                                    this.getFileUrl(imgid, true).then((res) => {
                                        if (res.status) {
                                            this.imgUrl = res.data;
                                            this.toPdfHtml("pdf", "zsInfo", "gradePrint", true);
                                            $("#mians").css("display", "none");
                                            $(".page span").html(this.totalPage);
                                        }
                                    });
                                } else {
                                    downLoad(imgid, this.zpUuid).then((res) => {
                                        if (res.status) {
                                            this.imgUrl = res.data;
                                            this.toPdfHtml("pdf", "zsInfo", "gradePrint", true);
                                            $("#mians").css("display", "none");
                                            $(".page span").html(this.totalPage);
                                        }
                                    });
                                }
                            } else {
                                this.$nextTick(() => {
                                    this.toPdfHtml("pdf", "zsInfo", "gradePrint", true);
                                    $("#mians").css("display", "none");
                                    $(".page span").html(this.totalPage);
                                })

                            }

                            return;
                        }
                    });
                }
            },
            getDetail() {
                        if (this.query.mbh) {
                            this.getTemplateList(this.query.mbh);
                        }
                        this.examForm = this.zkzInfo
                        this.mbmc2 = this.zkzInfo.zkzmc;
                        let mbbm = this.zkzInfo.zkzsmh;
                        if (mbbm) {
                            let newmbbm = mbbm.split(",");
                            const list = [];
                            newmbbm.forEach((v, i) => {
                                list[i] = getMbInfo(v);
                            });
                            Promise.all(list).then((values) => {
                                values.forEach((k) => {
                                    let text = k.data.mbnr;
                                    text = text.replace(/background:white/g, "");
                                    this.ruleList.push({
                                        mbmc: k.data.mbmc,
                                        mbnr: text,
                                    });
                                });
                                this.$nextTick(() => {
                                    let urls = [];
                                    $(".outerCotent")
                                        .find("img")
                                        .each((x, y) => urls.push(y.src));
                                    if (urls.length) {
                                        urlToBase(urls).then((res) => {
                                            if (res.status) {
                                                $(".outerCotent")
                                                    .find("img")
                                                    .each((x, y) => {
                                                        if (y.src.startsWith("http")) {
                                                            y.src = res.data[y.src];
                                                        }
                                                    });
                                                if (this.zkzInfo.sfdycns) {
                                                    this.zkzgzcnhList.forEach((v) => {
                                                        this.toPdfHtml("pdf", v + "1", v, false);
                                                    });
                                                }
                                            }
                                        });
                                    } else {
                                        if (this.zkzInfo.sfdycns) {
                                            this.zkzgzcnhList.forEach((v) => {
                                                this.toPdfHtml("pdf", v + "1", v, false);
                                            });
                                        }
                                    }
                                });
                            });
                        }
            },
            isHidden() {
                this.isShowZp = !this.isShowZp;
                this.hiddenZp();
            },
            //显示隐藏照片
            hiddenZp() {
                if (this.isShowZp == false) {
                    $(".guding-top").css("width", "99%");
                    // $(".guding-top").removeClass("border-right");
                    $(".guding-img").css("display", "none");
                    $(".guding-col").addClass("less-width");
                } else {
                    $(".guding-img").css("display", "flex");
                    $(".guding-top").css("width", "80%");
                    // $(".guding-top").removeClass("border-right");
                    $(".guding-col").removeClass("less-width");
                }
            },
            // 获取图片验证码
            getImageCode: function () {
                var _this = this;
                getcode().then((res) => {
                    if (res.status) {
                        _this.imgCode = res.data;
                        _this.formData.uuid = res.data.uuid;
                    }
                });
            },
            agreeCommit() {
                let _this = this;
                console.log(this.zkzgzcnhList,this.zkzgzcnhIndex)
                if (this.zkzgzcnhList[this.zkzgzcnhIndex]) {
                    getMbInfo(this.zkzgzcnhList[this.zkzgzcnhIndex]).then((res) => {
                        if (res.status) {
                            let text = res.data.mbnr;
                            text = text.replace(/background:white/g, "");
                            text = text.replace(
                                /{XM}/g,
                                this.formData.ksxm
                            );
                            //   text = text.replace(/{ZJLX}/g, this.userInfo.sfzjlxmc);
                            text = text.replace(
                                /{SFZH}/g,
                                this.formData.zjhm
                            );
                            text = text.replace(/{RQ}/g, format(new Date(), "yyyy年MM月dd日"));
                            res.data.mbnr = text;
                            this.zkzcnsInfoList.push(res.data);
                            _this.zkzsmInfo = res.data || "";
                            _this.Countdown = 3;
                            this.zkzgzcnhIndex++;
                            _this.timer = setInterval(function () {
                                if (_this.Countdown > 0) {
                                    _this.Countdown -= 1;
                                } else {
                                    clearInterval(_this.timer);
                                }
                            }, 1000);
                        }
                    });
                }



            },
            getTableRate() {
                // 获取表格字段占比
                let sum = 0;
                let arr = this.list4;
                for (var i = 0; i < arr.length; i++) {
                    sum += arr[i].zdbl;
                }
                if (sum > 100) {
                    this.sumWidth = sum;
                }
            },
            pageSign() {
                let height = $(".outerCotent").height();
                this.pageNum = parseInt(height / this.pageHeight);
            },
            toPdfHtml(id, id1, id2, zsAdd) {
                if ($("#" + id1).length > 0) {
                    return false;
                }
                this.pdfNum = 1;
                this.pdfHeight = 0;
                $("#" + id).append("<div id='" + id1 + "'></div>");
                $("#" + id1).append(
                    "<div class='pdf' id='" +
                    id1 +
                    "1' page='1' style='height:" +
                    this.pageHeight +
                    "px'></div>"
                );
                $("#" + id1 + "1").append(
                    "<div class='page' style='height:30px'>" +
                    this.totalPage +
                    "/<span></span></div>"
                );
                // 考试规则
                $("#" + id2 + " .gzContent").each((i, item) => {
                    // 标题
                    $(item)
                        .children("p")
                        .each((i, item1) => {
                            this.addItem(item1, $(item1).outerHeight(true), id1);
                        });
                    // 内容
                    $(item)
                        .find(".contentMain")
                        .children()
                        .each((i, item1) => {
                            $(item1).css("width", "unset");
                            this.addItem(item1, $(item1).outerHeight(true), id1);
                        });
                });
                // 准考证内容
                if (zsAdd) {
                    let zsItem = $("#" + id2 + " .zsContent")[0];
                    this.addItem(zsItem, $(zsItem).outerHeight(true), id1, this.imgUrl);
                }
            },
            addItem(item, height, id, imgurl) {
                let paddingHeight = 84 + 30;
                height = Number(height);
                // 当前pdf高度大于0，且未添加新对象高度前后的页数不一致则换页
                if (
                    this.pdfHeight > 0 &&
                    parseInt((this.pdfHeight + paddingHeight) / this.pageHeight) !=
                    parseInt((this.pdfHeight + height + paddingHeight) / this.pageHeight)
                ) {
                    // pdf页数加1
                    this.totalPage += 1;
                    this.pdfNum += 1;
                    this.pdfHeight = (this.pdfNum - 1) * this.pageHeight;
                    $("#" + id).append(
                        "<div class='pdf' id='" +
                        id +
                        this.pdfNum +
                        "' page='" +
                        this.pdfNum +
                        "'style='height:" +
                        this.pageHeight +
                        "px'></div>"
                    );
                    $("#" + id + this.pdfNum).append(
                        "<div class='page' style='height:30px'>" +
                        this.totalPage +
                        "/<span></span></div>"
                    );
                }
                if (imgurl) {
                    $("#" + id + " .pdf").css("background-image", "url(" + imgurl + ")");
                }
                this.pdfHeight += height;
                // 将内容放入div中
                $(item)
                    .clone()
                    .appendTo($("#" + id + this.pdfNum));
                // $("#" + id + " .pdf").css("border", "1px #000000 solid");
                // $("#" + id + " .pdf").css("padding", "126px 93px 97px");
                $("#" + id + " .pdf").css("padding", "56px 70px 28px");
            },
            noSearch() {
                this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
                getZkzSubject({
                    zkzkid: this.query.sid,
                    zkzyxz: false,
                }).then((res) => {
                    if (res.status) {
                        var item = res.data;
                        this.subVals = item;
                        this.examineeData = res.data[0];
                        let userZpdz = res.data[0].zpdz;
                        let zkzkid = this.query.sid;
                        this.sfzjh = res.data[0].sfzjhEnc;

                        this.agreeCommit();
                    }
                });
            },
            preview() {
                console.log(this.previewdata);
                this.userInfo = {
                    xm: this.previewdata.ksxm,
                    ksxm: this.previewdata.ksxm,
                    zjhm: this.previewdata.zjhm,
                    zkzh: this.previewdata.zkzh,
                    zkzyxz: false,
                };
                adminGetZkz({
                    zkzkid: this.query.sid,
                    zjhm: this.previewdata.sfzjhEnc,
                    ytoken: this.previewdata.token,
                }).then((res) => {
                    if (res.status) {
                        if (res.data) {
                            this.zpUuid = res.data.zpUuid;
                            var item = res.data.zkzList || [];
                            item.forEach((ele) => {
                                let time = ele.ksrq.replace(/年/g, "-");
                                time = time.replace(/月/g, "-");
                                time = time.replace(/日/g, "");
                                let sj = ele.kssj.split("-")[0];
                                ele.ksTime = time + " " + sj;
                            });
                            item = item.sort((x, y) => {
                                return (
                                    new Date(x.ksTime.replace(/-/g, "/")).getTime() -
                                    new Date(y.ksTime.replace(/-/g, "/")).getTime()
                                );
                            });
                            this.subVals = item;
                            this.examineeData = item[0];
                            this.sfzjh = item[0].sfzjhEnc;
                            this.agreeCommit();
                        } else {
                            this.$message.error(res.message);
                        }
                    }
                });
            },

            getCodeZkz() {
              let key = this.query.key
              let search
              if(this.query.sfkwks){
                search = getCodeZkzKw({key});
              }else{
                search = getCodeZkz({key});
              }
              search.then(res => {
                    if (res.status) {
                        if (res.data) {
                            this.zpUuid = res.data.zpUuid;
                            var item = res.data.zkzList;
                            this.qrcode = res.data.qrcode
                            this.subVals = item;
                            this.examineeData = item[0];
                            let userZpdz = item[0].zpdz;
                            let zkzkid = this.query.sid;
                            this.sfzjh = item[0].zjhm;
                            if (!this.formData.zjhm) {
                                this.formData.zjhm = item[0].zjhm || "";
                            }
                            this.agreeCommit();
                            this.getDetail();
                        } else {
                            this.$message.error(res.message);
                        }
                    }
                })
            }
        },
        created() {
            if (!!window.ActiveXObject || "ActiveXObject" in window) {
                this.$alert(
                    "为了更好的使用本系统，我们推荐您使用谷歌Chrome浏览器或360极速模式！",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        callback: () => {
                            window.location.href = "/browser";
                        },
                    }
                );
            }

        },
        mounted() {
            this.DPI = window.screen.deviceXDPI;
            this.query = this.$route.query;
            this.formData.zkzkid = this.query.sid;
            let  sfkwks= this.query.sfkwks;
            getZkzInfo(this.query.sid,sfkwks).then((res) => {
                if (res.status) {
                    this.zkzInfo = res.data;
                    let list = res.data.cxtj
                        ? res.data.cxtj.split(",")
                        : ["ksxm", "zjhm", "zkzh"];
                    let obj = {};
                    list.forEach((v) => {
                        obj[v] = v;
                    });
                    this.cxtj = obj;
                    this.zkzgzcnhList = res.data.zkzgzcnh
                        ? res.data.zkzgzcnh.split(",")
                        : [];
                    this.zkzgzcnhIndex = 0;
                    this.$nextTick(()=>{
                        this.getCodeZkz()
                    })
                    console.log(this.zkzgzcnhList,this.zkzgzcnhList)

                }
            });



        },
    };
</script>

<template>
    <div>
        <div class="main">
            <div class="wp">
                <div class="ticketPrint" style="min-height: 70vh">
                    <div>
                        <div class="card" style="background: #ababab" v-if="printShow">
                            <div class="card-body" style="">
                                <div class="outerCotent py-3">
                                    <div
                                            class="style-set-box h-100 white-bg"
                                            :style="{ width: moduleType == '1' ? '1103px' : '780px' }"
                                    >
                                        <el-row id="gradePrint" class="container">
                                            <div
                                                    id="pdf"
                                                    class="forbiddenText"
                                                    :style="{
                          width: moduleType == '1' ? '1103px' : '780px',
                        }"
                                            ></div>
                                            <el-col
                                                    :span="24"
                                                    ref="main"
                                                    id="mians"
                                                    class="style-right h-100"
                                                    :style="{ backgroundImage: 'url(' + imgUrl + ')' }"
                                            >
                                                <div class="w100">
                                                    <div
                                                            :id="'nobgColor' + i"
                                                            class="gzContent nobgColor"
                                                            v-for="(item, i) in ruleList"
                                                            :key="i"
                                                    >
                                                        <!-- <p
                                                          class="text-center text-dark pb-2"
                                                          style="font-weight: 600; font-size: 16px"
                                                        >
                                                          {{ item.mbmc }}
                                                        </p> -->
                                                        <div
                                                                class="contentMain"
                                                                v-html="item.mbnr"
                                                                style="font-family: 微软雅黑,华文细黑,宋体,黑体,arial,Hiragino Sans GB,Microsoft Yahei,Tahoma,Arial,Helvetica,STHeiti"
                                                        ></div>
                                                    </div>
                                                </div>
                                                <div class="zsContent">
                                                    <div
                                                            class="w100 cjd_title mb-2"
                                                            style="
                              white-space: pre-wrap;
                              font-size: 18px;
                              font-weight: 600;
                            "
                                                    >
                                                        {{ examForm.zkzmc }}
                                                    </div>

                                                    <div class="exam-form">
                                                        <el-row style="" class="border-diff top-height">
                                                            <el-col class="guding-top">
                                                                <div
                                                                        ref="list1Container"
                                                                        class="flexList guding-col"
                                                                        :list="list2"
                                                                        group="people"
                                                                        style="min-height: 40px"
                                                                >
                                                                    <div
                                                                            class="flexList msgLabel"
                                                                            :class="[
                                      'w' + element.zdbl,
                                      element.txlx == 'textarea'
                                        ? 'msgtextarea'
                                        : '',
                                    ]"
                                                                            v-for="element in list2"
                                                                            :key="element.sid"
                                                                    >
                                                                        <div class="style-name">
                                                                            {{
                                                                            element.zdymc
                                                                            ? element.zdymc
                                                                            : element.zdmc
                                                                            }}：
                                                                        </div>
                                                                        <div class="style-val flexList">
                                                                            {{ examineeData[element.zddm] }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </el-col>
                                                            <el-col class="guding-img">
                                                                <img
                                                                        id="user_head"
                                                                        class="userImg"
                                                                        :src="'data:image/jpeg;base64' +zpdz ? zpdz : defaultZpdz"
                                                                        alt=""
                                                                        style="width: 96px; height: 120px"
                                                                />
                                                            </el-col>
                                                            <el-col>
                                                                <div
                                                                        style="width: 99%; flex-wrap: wrap"
                                                                        class="flexList fs-xs less-width"
                                                                        :list="list3"
                                                                        group="people"
                                                                >
                                                                    <div
                                                                            class="flexList msgLabel"
                                                                            :class="[
                                      'w' + element.zdbl,
                                      element.txlx == 'textarea'
                                        ? 'msgtextarea'
                                        : '',
                                    ]"
                                                                            v-for="element in list3"
                                                                            :key="element.sid"
                                                                    >
                                                                        <div class="style-name">
                                                                            {{
                                                                            element.zdymc
                                                                            ? element.zdymc
                                                                            : element.zdmc
                                                                            }}：
                                                                        </div>
                                                                        <div class="style-val flexList">
                                                                            {{ examineeData[element.zddm] }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </el-col>
                                                        </el-row>
                                                        <el-row>
                                                            <table class="table zkzTable mt-3">
                                                                <thead>
                                                                <th
                                                                        v-for="obj in list4"
                                                                        :key="obj.sid"
                                                                        :style="{
                                      width:
                                        (sumWidth && sumWidth != 0
                                          ? (obj.zdbl / sumWidth).toFixed(3) *
                                            100
                                          : obj.zdbl) + '%',
                                    }"
                                                                >
                                                                    {{ obj.zdymc ? obj.zdymc : obj.zdmc }}
                                                                </th>
                                                                </thead>
                                                                <tbody v-if="subVals.length">
                                                                <tr
                                                                        v-for="(value, index) in subVals"
                                                                        :key="index"
                                                                >
                                                                    <td v-for="obj in list4" :key="obj.sid">
                                                                        {{ value[obj.zddm] }}
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </el-row>
                                                    </div>
                                                    <div class="w100 mb-3">
                                                        <!-- <div
                                                          class="text-dark font-size-12"
                                                          style="white-space: pre-wrap"
                                                        >
                                                          {{ examForm.zkzzysx }}
                                                        </div> -->
                                                        <div v-html="examForm.zkzzysx"></div>

                                                    </div>
                                                </div>
                                            </el-col>
                                        </el-row>
                                        <el-row
                                                class="zkzcnsList container"
                                                :style="{ width: moduleType == '1' ? '1103px' : '780px' }"
                                                style="padding: 56px 70px 28px"
                                                :id="item.mbbm"
                                                v-for="(item, i) in zkzcnsInfoList"
                                                :key="i"
                                        >
                                            <div class="w100">
                                                <div class="gzContent nobgColor">
                                                    <!-- <p
                                                      class="text-center font-size-20 text-dark"
                                                      style="font-weight: 600; font-size: 16px"
                                                    >
                                                      {{ item.mbmc }}
                                                    </p> -->
                                                    <div
                                                            v-html="item.mbnr"
                                                            class="contentMain"
                                                            style="font-family: 微软雅黑,华文细黑,宋体,黑体,arial,Hiragino Sans GB,Microsoft Yahei,Tahoma,Arial,Helvetica,STHeiti"
                                                    ></div>
                                                </div>
                                            </div>
                                        </el-row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
