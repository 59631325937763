export const noChange=()=>{
// 1.禁用右键菜单
    document.oncontextmenu = new Function("event.returnValue=false");
    // 2.禁用鼠标选中
    document.onselectstart = new Function("event.returnValue=false");
    // 3.禁止键盘F12键
    document.addEventListener("keydown", function (e) {
        if (e.key == "F12"||(e.ctrlKey && e.shiftKey && e.keyCode== "73")) {
            e.preventDefault(); // 如果按下键F12,阻止事件
        }

    });
    var h = window.innerHeight,
        w = window.innerWidth;

    //如果用户在工具栏调起开发者工具，那么判断浏览器的可视高度和可视宽度是否有改变，如有改变则重新加载
    window.onresize = function() {
        if (h != window.innerHeight || w != window.innerWidth) {
            window.location.reload();

        }
    }
    /*好吧，你的开发者工具是单独的窗口显示，不会改变原来网页的高度和宽度，
    但是你只要修改页面元素我就重新加载一次数据,让你无法修改页面元素（不支持IE9以下浏览器）*/
   /* if (window.addEventListener) {
        window.addEventListener("DOMCharacterDataModified", function() {
            window.location.reload();
        }, true);
        window.addEventListener("DOMAttributeNameChanged", function() {
            window.location.reload();
        }, true);
        window.addEventListener("DOMCharacterDataModified", function() {
            window.location.reload();
        }, true);
        window.addEventListener("DOMElementNameChanged", function() {
            window.location.reload();
        }, true);
        window.addEventListener("DOMNodeInserted", function() {
            window.location.reload();
        }, true);
        window.addEventListener("DOMNodeInsertedIntoDocument", function() {
            window.location.reload();
        }, true);
        window.addEventListener("DOMNodeRemoved", function() {
            window.location.reload();
        }, true);
        window.addEventListener("DOMNodeRemovedFromDocument", function() {
            window.location.reload();
        }, true);
        window.addEventListener("DOMSubtreeModified", function() {
            window.location.reload();
        }, true);
    }*/
}
